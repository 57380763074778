@import 'styles/variables';

#cx-validator-progress {
    .validator {
        color: black;

        @media (max-width: ($desktop - 1)) {
            color: $white;

        }
    }

    .progress {
        height: 4px;
        border-radius: 3px;
        background-color: transparent;
    }

    .progress-bar-striped {
        background-image: none;
        border-radius: 3px;
        width: 0%;
    }

    .red {
        color: rgba(253, 74, 74, 1);
        font-weight: bold;
    }

    .green {
        color: $green;
    }

    .background-red {
        background-color: $red;
    }

    .background-yellow {
        background-color: $yellow;
    }

    .background-green {
        background-color: $green;
    }
}
