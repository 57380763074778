@import 'styles/variables';

#cx-trade-intro {
    padding-top: $bigPad;

    @media (width > $phone) {
        padding-top: $largePad;
    }

    @media (width >= $desktop) {
        padding-top: $hugePad;
    }

}
