@use "styles/mixins" as m;

cx-tooltip {
    @include m.square(var(--size));
    
    .tooltip-icon {
        font-size: var(--size);
    }

    &.cx-tooltip--colored .tooltip-icon {
        color: var(--color);
    }
}
