@use '@chicksgroup/styles/variables' as v-global;
@use '@chicksgroup/styles/mixins' as m-global;
@use 'styles/variables' as v;
@use 'styles/mixins' as m;

#cx-try-again-box {
    background-color: var(--cg-grey-100);
    @include m-global.flex-column($align: center);
    @include m.regular-container-paddings($offset: 1px);

    .unknown-container--divider {
        @include m-global.size(100%, 1px);
        margin-top: 20px;
        opacity: 1;
        margin-bottom: 20px;
        background-color: v.$color-grey-300;
        display: block;
        border:none;
    }

    button {
        border-radius: 4px !important;
    }

    p {
        @include m.no-spacing(true);
    }
    
    .cx-try-again-box__container {
        @include m.container-fluid(100%);
    }

    .content a {
        @include m-global.font(v-global.$medium, 500, $color: v-global.$purple-text !important, $spacing: 0.04px);
    }

    .box {
        @include m.container-fluid(1296px);
        padding: v-global.$hugePad;
        border-radius: 4px;

        @media ((width <= calc(v-global.$tablet + 1px))) {
            padding: v-global.$bigPad v-global.$hugePad;
        }
    }

    .header-container {
        --md-icon-size: 48px;
        --cds-icon-color: var(--cg-grey-500);
        max-height: 48px;
        gap: 12px;
        align-items: center;
    }

    .expired {
        @include m-global.font(v-global.$huge, 700, 36px, 0px, var(--cg-grey-900) !important );

        h1, h2, h3, h4 {
            margin: 0;
            color: var(--cg-grey-900);
        }
    }
    
    .content {
            @include m-global.font(v-global.$medium, 400, $color: var(--cg-grey-900), $spacing: 0.24px);
            font-style: normal;
            margin-bottom: v-global.$bigPad;
        
        p {
            @include m-global.font($height: 32px, $spacing: 0.24px);

            @media (width <= v-global.$tablet) {
                @include m-global.font(v-global.$regular, $height: 24px, $spacing: 0.014px);
            }
        }
    }

    .btn-content,
    .btn-try {
        @include m.container-fluid(248px);
        height: 52px;
    }

    .btn-try {
        @include m-global.font(v-global.$medium, 500, 20px, 0.04px, var(--cg-grey-100) !important);
        height: 52px;
    }
    
    p {
        font-family: v-global.$primaryfont;
    }

    @include m.media(v-global.$tablet, $offset: 1px) {
        background: transparent;
        background-color: v-global.$white;

        .box {
            box-sizing: border-box;
            background-color: transparent;
        }

        .expired {
            font-size: v-global.$huge;

            @media (width <= v-global.$phone) {
                @include m-global.font(v-global.$extralarge, $height: 28px);
            }

            p {
                line-height: 28px !important;
            }
        }
    }

    @include m.media(v-global.$phone) {
        .btn-content,
        .btn-try {
            @include m.container-fluid(100%);
        }

        .box {
            padding: v-global.$bigPad;

        }
    }
}
