@import 'variables';

.cx-background {
    background: linear-gradient(to right, $cx-purple 2.45%, $cx-blue 101.9%);
}

.blue-border-top {
    border-top: 2px solid $lightpurple;
}

.blue-border-bottom {
    border-bottom: 1px solid $lightpurple;
}

.light-grey-border-top {
    border-top: 2px solid rgba(51, 51, 51, .2);
}
