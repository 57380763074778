@use 'styles/variables' as v;
@use 'styles/mixins' as m;

#cx-id-selfie-verification-progress {
    width: fit-content;
    display: grid;
    align-items: center;
    grid-auto-flow: column;

    @media (width <= v.$phone) {
        grid-auto-flow: row;
        row-gap: 15px;
    }

    .verification-step {
        @include m.flex(center);
        font-weight: bold;
    }

    .number {
        @include m.square(27px);
        display: grid;
        align-items: center;
        justify-items: center;
        background-color: v.$blue;
        color: v.$white;
        font-weight: bold;
        border-radius: 50%;
    }

    .line {
        background-color: v.$blue;
        height: 2px;
        width: 23px;
    }

    .circle-icon {
        @include m.square(27px);
        position: unset;
    }
}
