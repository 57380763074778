@use 'styles/variables' as v;
@use 'styles/mixins' as m;
:root{
    --iconSize: 24px;
    --borderSize: 1px;
}

#cx-user-avatar {
    .mask {
        @include m.square(var(--iconSize) !important);
        background-color: v.$cx-purple-half;
        border-radius: 100%;
        opacity: 0;
        transition: opacity 0.3s ease;
        z-index: 20;
    }

    .user-img {
        display: inline-block;

        &:hover .mask {
            opacity: 1;
        }

        &.use-icon-size {
            height: var(--iconSize);
            display: inline-flex;
            align-items: center;
        }
    }

    .user-profile {
        @include m.square(var(--iconSize) !important);
        --md-icon-size: var(--iconSize);
        border-radius: 100%;
        object-fit: cover;
    }

    .user-profile-container.subscribed {
        $crownSize: calc(var(--iconSize) * 0.4);
        height: calc(var(--iconSize) + calc($crownSize * 0.218)) !important;
        @include m.square(fit-content);
        position: relative;

        .user-profile {
            border: var(--borderSize) solid v.$dark-mustard;
        }

        .user-crown {
            @include m.square($crownSize !important);
            position: absolute;
            left: 60.42%;
            right: 2.08%;
            top: 64%;
            bottom: 0%;
            z-index: 30;
        }
    }
}
