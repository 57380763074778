@import 'styles/variables';

$timerSize: 30px;
$timerSizeDevice: 24px;

#cx-timer {
    font-family: $primaryfont;

    .base-timer {
        max-height: $timerSize;
        max-width: $timerSize;
        min-height: $timerSize;
        min-width: $timerSize;

        @media (width <= $desktop) {
            max-height: $timerSizeDevice;
            max-width: $timerSizeDevice;
            min-height: $timerSizeDevice;
            min-width: $timerSizeDevice;
        }
    }

    .base-timer__circle {
        fill: none;
        stroke: none;
    }

    .base-timer__path-elapsed {
        stroke-width: $extra-tiny;
        stroke: rgba(216, 216, 216, 1);
    }

    .base-timer__label {
        width: $timerSize;
        height: $timerSize;
        top: 0;
        color: $black;
        letter-spacing: 0.384px;

        @media (width <= $desktop) {
            width: $timerSizeDevice;
            height: $timerSizeDevice;
            font-size: $tiny;
        }
    }

    .base-timer__path-remaining {
        stroke-width: $extra-tiny;
        stroke-linecap: butt;
        transform: rotate(90deg);
        transform-origin: center;
        stroke: $cx-purple;
    }

    .base-timer__svg {
        transform: scaleX(-1);
    }

    .label {
        line-height: $regular;
        letter-spacing: 0em;
    }

    @media (width >= $phone) {
        .reverse-row {
            flex-direction: row-reverse !important;
        }
    }
}
