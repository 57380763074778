@import 'styles/variables';
@import 'styles/_colors.scss';

#cx-tracking-progress {
    .cx-tracking-proggress-horizontal {
        font-family: $primaryfont;
        display: flex;
        align-items: center;
        position: relative;

        @media (max-width: $desktop) {
            width: 100%;
            padding-left: 25px;
            padding-right: 59px;

            .steps-container {
                padding-right: 64px;
            }
        }
    }

    .size-controller {
        width: 75%;
        margin-top: 40px !important;
        margin-bottom: 40px;
    }

    .size-controller-tooltip {
        width: 100%;
        margin-top: 22px !important;
        margin-bottom: 18px;
    }

    .font-size-controller {
        font-size: $extra-tiny !important;
    }

    @media (max-width: $phone) {
        border-radius: 15px;
        padding: 12px;
        height: 256px;
        position: relative;
        margin: 10px 0;
    }

    ul {
        list-style-type: none;
        margin-block-start: 0;
        margin-block-end: 0;
        padding-inline-start: 0;
        counter-reset: step;
    }

    .step {
        background-color: rgba(197, 204, 229, 1);
        width: 30px;
        height: 30px;
        border: none;
        color: $secondarycolor-text;

        &.step-active {
            background-color: $cx-purple !important;
            color: $white;

            .step-number::before {
                content: "circle";
                font-weight: normal;
            }
        }

        &.step-success {
            @extend .cx-background;
            color: $white;

            .step-number::before {
                content: "check";
                font-weight: normal;
            }
        }

        &.step-disabled {
            background-color: rgba(197, 204, 229, 1);
            color: $white;

            .step-number::before {
                content: "circle";
                font-weight: normal;
            }
        }

        .step-number::before {
            content: counter(step);
            counter-increment: step;
            font-weight: bold;
        }

        @media (max-width: $desktop) {            
            width: 27px;
            height: 27px;
        }

        @media (max-width: $phone) {
            width: 25px;
            height: 25px; 

            &.step-active {
                .step-number {
                    font-size: $regular;
                }
            }
        }
    }

    .step-tooltip {
        background-color: $white;
        width: 18px;
        height: 18px;
        border: none;
        color: $secondarycolor-text;

        &.step-active {
            background-color: $white !important;
            color: $purple-text;

            .step-number::before {
                content: "circle";
                font-weight: normal;
            }
        }

        &.step-success {
            background-color: $white;
            color: $purple-text;

            .step-number::before {
                content: "check";
                font-weight: normal;
            }
        }

        &.step-disabled {
            background-color: $white;
            color: $white;

            .step-number::before {
                content: "circle";
                font-weight: normal;
            }
        }

        .step-number::before {
            content: counter(step);
            counter-increment: step;
            font-weight: bold;
        }

        @media (max-width: $desktop) {            
            width: 27px;
            height: 27px;
        }

        @media (max-width: $phone) {
            width: 25px;
            height: 25px; 

            &.step-active {
                .step-number {
                    font-size: $regular;
                }
            }
        }
    }

    .cx-linear-progress {
        .mdc-linear-progress__buffer-bar {
            background-color: rgba(197, 204, 229, 1);
        }

        .mdc-linear-progress__bar-inner {
            border-color: $cx-purple;
        }
    }

    .cx-linear-progress-tooltip {
        .mdc-linear-progress__buffer-bar {
            background-color: $white;
        }

        .mdc-linear-progress__bar-inner {
            border-color: $white;
        }
    }

    .cx-tracking-proggress-vertical {
        top: 14px;
        left: 12px;

        mdc-linear-progress {
            width: 202px;
            transform: translate(12px, 10px) rotate(90deg);
            transform-origin: left;
        }

        .position-absolute {
            height: 228px;
        }
    }

    .status-container {
        padding: 0 87px;

        & .status {
            span {
                color: $darkergray;
                font-size: $regular;
                margin-left: 5px;
            }
        }

        & .status-tooltip {
            font-size: $small !important;
        }

        @media (max-width: $desktop) {
            padding: 0;
        }

        @media (max-width: $phone) {
            margin-left: 35px;
            height: 100%;
            padding: 5px 0;

            & .status {
                span {
                    margin-left: 0;
                    color: $black;
                }
            }
        }
    }

    .status-container-tooltip {
        padding: 0;

        & .status {
            span {
                color: $darkergray;
                font-size: $regular;
                margin-left: 5px;
            }
        }

        @media (max-width: $desktop) {
            padding: 0;
        }

        @media (max-width: $phone) {
            margin-left: 35px;
            height: 100%;
            padding: 5px 0;

            & .status {
                span {
                    margin-left: 0;
                    color: $black;
                }
            }
        }
    }

    .icon-inverted {
        filter: brightness(0) invert(1);
        width: 15px;
        height: auto;
    }
}

.background-color-controller {
    background-color: none;

    @media (max-width: $phone) {
        background-color: $inputgray;
    }
}

.background-color-controller-tooltip {
    background-color: none;
}