@use '@material/textfield';
@use 'styles/variables' as v;
@use '@chicksgroup/styles/mixins' as m;

#cx-wallet-input {
    .explanation-text {
        @include m.font(v.$regular, 400, v.$medium, 0.001em);
    }

    .double-input-wallet-address {
        width: 70%;

        & .mdc-notched-outline .mdc-notched-outline__trailing,
        label {
            @media (min-width: v.$phone) {
                border-top-right-radius: 0 !important;
                border-bottom-right-radius: 0 !important;
            }
        }
    }

    .double-input-extra-field {
        width: 29%;

        & .mdc-notched-outline .mdc-notched-outline__leading,
        label {
            @media (min-width: v.$phone) {
                border-top-left-radius: 0 !important;
                border-bottom-left-radius: 0 !important;
            }
        }
    }

    @media (max-width: v.$phone) {
        .double-input-wallet-address,
        .double-input-extra-field {
            width: 100%;

            label {
                border-radius: 12px;
            }
        }

        .double-input-wallet-address {
            margin-bottom: 15px;
        }
    }

    input,
    input::placeholder {
        font-size: v.$regular;

        @media (max-width: v.$phone) {
            font-size: v.$small;
        }
    }

    .circle-icon {
        position: absolute;
        right: 16px;
        top: 16px;
        width: 20px;
        height: 20px;
        font-size: v.$largeplusplus;
    }
    
    .mdc-text-field__input{
        padding-right: 30px;
    }

    .wallet-address-tooltip {
        --cds-tooltip-width: 250px;
    }

    .tooltip-icon {
        min-width: 24px;
    }
}
