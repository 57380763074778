@use "styles/variables" as v;
@use  "styles/mixins" as m;

#cx-user-dropdown {
    position: relative;
    gap: 3px;

    .cx-user-avatar-space {
        &.skeleton-loading {
            max-height: 24px;
        }
    }

    #cx-customer-portal-navigation {
        min-height: auto;
    }

    .username {
        letter-spacing: 0.28px;
    }

    .arrow {
        --md-icon-size: 12px;
        padding: 4px 2px;
        transition: transform ease 0.2s;
    }

    @mixin dropdown-active-styles() {
        .arrow-down {
            transform: rotate(180deg);
        }
    }

    @media (width >= v.$widescreen) {
        @include m.has-any(('.show', '.force-show')) {
            @include dropdown-active-styles();
        }
    }

    @media (width >= v.$widescreen) {
        &:hover {
            @include dropdown-active-styles();
        }
    }

    .name-width {
        max-width: 90px;
    }

    .btn {
        align-items: center;
        border-left: 2px solid v.$transparent;
        border-top: unset;
        border-bottom: unset;
        border-radius: 0;
        white-space: nowrap;
        transition: all 0.15s ease-in-out;
        border-color: v.$transparent;
        gap: 8px;
    }


    &.content-hovered {
        @media (width > v.$desktop) {
            &::after, &.inverted::after {
                content: '';
                position: absolute;
                left: 0;
                top: 95%;
                width: 100%;
                height: 2px;
                background-color: v.$cx-purple;
            }
    
            &.inverted::after {
                background-color: var(--cg-grey-100);
            }   
        }
    }

    .btn:focus {
        box-shadow: none !important;
        outline: none !important;
    }

    .alt-customer-dropdown-icon-up,
    .alt-customer-dropdown-icon-down {
        position: absolute;
        line-height: 100%;
        vertical-align: middle;
        margin-left: 8px;
        top: 28px;
        transition: all 0.15s ease-in-out;

        @media (max-width: v.$desktop) {
            margin-left: 6px;
            top: 27.5px;
        }
    }

    .dropdown-menu.force-show {
        display: block;
    }

    .dropdown-menu {
        @include m.container-fluid(294px, false, 0, 100vw);
        padding-top: 0;
        background-color: unset;
        border: 0;
        margin-top: 0;
        position: absolute;

        li {
            position: relative;
        }

        @media (max-width: v.$desktop) {
           padding-right: 8px;
        }

    }

    .alt-customer-dropdown-icon-up {
        visibility: hidden;
        opacity: 0;
    }

     .dropdown-menu > li > .submenu .dropdown-item:hover {
            background-position: 0;
        }

    .dropdown-orientation {
        left: auto;
        right: 0;
    }

    .btn-padding {
        padding: 0.9rem 0 0.9rem 0.2rem;

        @media (width > v.$phone) {
            padding: 1.15rem 0 1.15rem 0.2rem;
        }

        @media (width > v.$tablet) {
            padding: 1.3rem 0 1.3rem 0.2rem;
        }
    }

    @media (max-width: v.$phone) {
        .dropdown-menu:last-child {
            left: auto;
            right: 0;
        }
    }

    section[data-simplebar] {
        min-width: 242px;
        max-height: 360px;
        overflow-x: hidden;
        height: unset;
    }

    &.bottom-border::after {
        content: '';
        position: absolute;
        bottom: 1px;
        left: 10%;
        width: 100%;
        height: 2px;
        background-color: v.$purple-text;
    }

    &.bottom-border.border-bottom-white::after {
        background-color: var(--cg-grey-100);
    }

    .user-profile {
        pointer-events: none;
    }
}
